<template>
    <div :key="page_key">
        <LiefengModal :title="title" :value="display" :fullscreen="false" :width="600" :height="600" @input="onChangeModal(false)">
            <template v-slot:contentarea>
                <div class="container">
                    <div class="content">
                        <Form :model="formData" :label-colon="true" :label-width="100" :rules="formDataValidate" ref="form">
                            <Transfer
                                v-if="labelList"
                                class="label-community-transfer-box"
                                :data="labelList"
                                :target-keys="labelTargetKeys"
                                :list-style="listStyle"
                                :render-format="transferRender"
                                :titles="['源数据', '目标数据']"
                                filterable
                                :filter-method="filterMethod"
                                @on-change="onChangeLabel"
                            ></Transfer>
                        </Form>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right:10px" @click="onChangeModal(false)">取消</Button>
                <Button type="primary" @click="submit">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"

export default {
    components: { LiefengModal },

    data() {
        return {
            display: false,
            page_key: this.$core.randomString(),

            labelList: null,
            labelTargetKeys: [],

            listStyle: {
                width: "250px",
                height: "500px",
            },
        }
    },

    props: {
        title: String,
        api: {
            type: String,
            required: false,
        },
        data: {
            type: Array,
            required: false,
        },
        idKey: {
            type: String,
            default: "id",
        },
        valueKey: {
            type: String,
            default: "value",
        },
    },

    watch: {
        data: {
            handler(val) {
                this.processData(val)
            },
            immediate: true,
        },
    },

    methods: {
        processData(data) {
            if (!data) return

            const result = []

            for (let i = 0; i < data.length; i++) {
                const e = data[i]

                result.push({
                    key: String(e[this.idKey]),
                    value: e[this.valueKey],
                })
            }

            this.labelList = result

            return result
        },
        /**
         * 获取所有标签
         */
        getAllLabel() {
            return new Promise(async resolve => {
                this.$Spin.show()

                this.$get(this.api)
                    .then(res => {
                        if (res.code == 200) {
                            this.processData(res.dataList)

                            resolve(res.dataList)
                        }
                    })
                    .finally(_ => {
                        this.$Spin.hide()
                    })
            })
        },

        transferRender(item) {
            return item.value
        },

        onChangeLabel(e) {
            this.labelTargetKeys = e
        },

        async open(codes = []) {
            if (!this.labelList) {
                await this.getAllLabel()
            }

            this.labelTargetKeys = codes
            this.onChangeModal(true)
        },

        /**
         * 提交事件
         */
        submit() {
            const keys = this.labelTargetKeys
            const labels = this.labelList
            const res = []

            for (let i = 0; i < labels.length; i++) {
                let l = labels[i]

                let idx = keys.indexOf(l.key)

                if (idx > -1) {
                    res.push(l)
                }
            }

            this.$emit("on-submit", {
                label: "comp-label",
                value: {
                    keys: keys,
                    entitys: res,
                },
            })

            this.onChangeModal(false)
        },

        onChangeModal(flag) {
            // 改变弹窗显示状态
            this.display = flag
            // 如果为显示模式，刷新 Key
            if (flag) {
                this.page_key = this.$core.randomString()
            } else {
                // 父页面刷新列表
                this.$emit("resetBtn")
            }
        },

        filterMethod(data, query) {
            return data.value.indexOf(query) > -1
        },
    },
}
</script>

<style lang="less" scoped>
.label-community-transfer-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        padding-top: 20px;
        flex: 1;
        height: 100%;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #22a96c;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}
</style>
