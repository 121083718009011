<template>
    <div class="elem-label-box">
        <div class="textarea-label-box" @click="onDisplayLabelPage">
            <div class="label-box">
                <div class="label-item-box" v-for="(item, idx) in labels" :key="idx">
                    <p class="value">{{ item.value }}</p>
                    <div class="delete-btn" @click.stop="onDeleteLabel(idx)"></div>
                </div>
                <p class="hint" v-if="!labels || labels.length <= 0">{{ place_holder }}</p>
            </div>
            <div class="arrow"></div>
        </div>

        <comp-label ref="comp_label" :title="title" :id-key="idKey" :value-key="valueKey" :data="labelList" :value="getKeys()" @on-submit="onSubmitLabel"></comp-label>
    </div>
</template>

<script>
import Headway from "../../jointly/utils/headway"
import CompLabel from "./comp-label.vue"

export default {
    components: { CompLabel },
    data() {
        return {
            labels: [],
            labelList: null,
            place_holder: null,
        }
    },
    props: {
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        idKey: {
            type: String,
            default: "id",
        },
        valueKey: {
            type: String,
            default: "value",
        },
        title: String,
        placeholder: {
            type: String,
            default: "",
        },
        name: String,
        value: Array,
        type: {
            type: String,
            default: "textarea",
        },
        operatorId: String,
        addType:{
            type: String,
            default: "",
        }
    },

    watch: {
        value: {
            handler(val) {
                if (!val) return (this.labels = this.keys = [])
                if (val === this.keys) return

                new Headway(this, "labelList").on().then(list => {
                    const res = []

                    for (let i = 0; i < list.length; i++) {
                        const e = list[i]
                        let id = e[this.idKey]

                        if (val.indexOf(id) > -1) {
                            res.push({
                                key: id,
                                value: e[this.valueKey],
                            })
                        }
                    }

                    this.labels = res
                    this.keys = this.getKeys()
                })
            },
        },

        labels() {
            this.keys = this.getKeys()
        },
    },

    async created() {
        await this.getData()
    },

    mounted() {
        // 占位符
        this.place_holder = this.placeholder ? this.placeholder + (this.required ? "" : "（可选）") : `选择${this.title || this.name || "选项"}${this.required ? "" : "（可选）"}`
    },

    methods: {
        async getData() {
            await this.$get(
                this.operatorId && this.operatorId != ""
                    ? this.addType && this.addType != '' ? `/gateway/apps/o2o/api/o2o/admin/listOperatorSupplier?supplierName=&operatorId=${this.operatorId}` : `/gateway/apps/o2o/api/o2o/admin/listOperatorHasSupplier?supplierName=&operatorId=${this.operatorId || ""}` : "/gateway/apps/o2o/api/o2o/admin/listSupplierAll?supplierName="
            ).then(res => {
                console.log('谁先触发1');
                if (res.code == 200) {
                    this.labelList = res.dataList
                }
            })
        },

        onDisplayLabelPage() {
            this.$refs.comp_label.open(this.keys?.map(e => String(e)))
        },

        /**
         * 监听删除
         */
        onDeleteLabel(idx) {
            this.labels.splice(idx, 1)
            this.keys = this.getKeys()

            this.$emit("on-change", {
                value: {
                    entitys: this.labels,
                    keys: this.keys,
                },
                type: "elem-label",
                name: this.name,
            })
        },

        getValue() {
            return this.labels
        },

        onSubmitLabel(evt) {
            this.labels = evt.value.entitys

            this.keys = this.getKeys()
            console.log(this.keys,this.labels);

            this.$emit("on-change", {
                value: {
                    entitys: this.labels,
                    keys: this.keys,
                },
                type: "elem-label",
                name: this.name,
            })
        },

        getKeys() {
            const ks = []

            for (let i = 0, l = this.labels; i < l.length; i++) {
                ks.push((l[i].key))
            }

            return ks
        },
    },
}
</script>

<style lang="less">
.elem-label-box {
    width: 100%;
    font-size: 14px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #e3e3e3;
    cursor: pointer;
}

.elem-label-box:hover {
    border-color: #b3b3b3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.elem-label-box .label-textarea {
    display: none;
}

.input-label-box {
    width: 100%;
    height: 40px;
    padding: 0 50px 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.elem-label-box .label-box {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    min-height: 42px;

    .hint {
        color: #c3c3c3;
        margin-left: 5px;
    }
}

.elem-label-box .label-box .label-item-box {
    margin-right: 10px;
    padding: 6px 5px 6px 10px;
    display: flex;
    align-items: center;
    background: #2faaf7;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    max-width: 100%;
    word-break: break-word;
    margin: 3px;

    .value {
        font-size: 14px;
        line-height: 14px;
        color: #fff;
    }
}

.elem-label-box .label-box .label-item-box:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.elem-label-box .label-box .label-item-box .delete-btn {
    position: relative;
    cursor: pointer;
    padding: 3px 8px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
    display: flex;

    &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: ~"calc(50% - 1px)";
        height: 1px;
        right: 2px;
        background: #fff;
        transform: rotate(45deg);
        border-radius: 1px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 2px;
        top: ~"calc(50% - 1px)";
        height: 1px;
        right: 2px;
        background: #fff;
        transform: rotate(-45deg);
        border-radius: 1px;
    }
}

.elem-label-box .input-box {
    flex-grow: 1;
}

.textarea-label-box {
    width: 100%;
    display: flex;
    align-items: center;

    .label-box {
        padding: 5px;
        flex-wrap: wrap;
        flex-grow: 1;
        max-width: ~"calc(100% - 30px)";

        .label-item-box {
            margin: 3px;
        }
    }

    .arrow {
        position: relative;
        margin: 0 5px;
        flex-shrink: 0;
        width: 15px;
        height: 15px;

        &::after {
            content: "";
            position: absolute;
            left: 3px;
            top: ~"calc(50% - 3px)";
            height: 2px;
            right: 5px;
            background: #666;
            transform-origin: left;
            transform: rotate(45deg);
            border-radius: 2px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 5px;
            top: ~"calc(50% - 3px)";
            height: 2px;
            right: 3px;
            background: #666;
            transform-origin: right;
            transform: rotate(-45deg);
            border-radius: 2px;
        }
    }
}

.textarea-label-box .textarea-box .textarea {
    height: 100px;
    min-width: 100%;
    max-width: 100%;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
}
</style>
