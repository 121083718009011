<template>
    <comp-table title="自提点（导购点）管理" ref="comp_table" edit-key="id" :columns="columns" :table-api="tableApi" :delete-api="v => '/gateway/apps/o2o/api/terminal/deleteTerminalGroup?terminalGroupId=' + v.id" :formComponent="formComponent" :operate="getTableOperate"></comp-table>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompTable from "../../../jointly/components/comp-table.vue"
import formComponent from "./form.vue"

export default {
    components: { CompTable },

    data() {
        return {
            formComponent: formComponent,

            tableApi: "/gateway/apps/o2o/api/terminal/terminalGroupList?oemCode=" + parent.vue.oemInfo.oemCode,

            columns: [
                {
                    title: "自提点ID",
                    key: "id",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "自提点名称",
                    key: "terminalGroupName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "联系人",
                    key: "contactperson",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "联系电话",
                    key: "phone",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "地址",
                    key: "address",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "启禁用",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.idisplay === 1 ? "启用" : "禁用")
                    },
                },
            ],
        }
    },

    methods: {
        /**
         * 获取表格操作菜单
         */
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                Route.jump("/guidecategory?t=" + params.row.id)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "分类管理"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                Route.jump("/terminal_hot?i=" + params.row.id)
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "热销品管理"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                const d = params.row.idisplay

                                this.$post("/gateway/apps/o2o/api/terminal/enableTerminalGroup", {
                                    terminalGroupId: params.row.id,
                                    idisplay: d === 1 ? 0 : 1,
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success((d === 1 ? "禁用" : "启用") + "成功")
                                        params.row.idisplay = d === 1 ? 0 : 1
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    params.row.idisplay === 1 ? "禁用" : "启用"
                ),
            ]
        },
    },
}
</script>
<style lang="less"></style>
